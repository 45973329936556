import React from 'react';
import {
    optin_rates,
    rates,
    getPudoLockers,
    getPudoLockersGuest,
    shipments_create,
    getAvailableLocker,
    getSettings,
    quoteGetSettings,
    what3words,
    checkMigrate,
    invoiceable_dates,
    generateInvoice,
    guest_optin_rates,
    guest_shipments_create,
    guest_rates
} from 'infra/services/api';

const NewOrderLogic = () => {
    return {
        generateInvoice: async (payload) => {
            const response = await generateInvoice(payload);
            return response;
        },
        checkMigrate: async (payload) => {
            const response = await checkMigrate(payload);
            return response;
        },
        getPudoLockers: async () => {
            const response = await getPudoLockers();
            return response;
        },
        getAvailableLocker: async (terminal) => {
            const response = await getAvailableLocker(terminal);
            return response;
        },
        rates: async (paylaod) => {
            const response = await rates(paylaod);
            return response;
        },
        guest_rates: async (paylaod) => {
            const response = await guest_rates(paylaod);
            return response;
        },
        optin_rates: async (paylaod) => {
            const response = await optin_rates(paylaod);
            return response;
        },
        guest_optin_rates: async (paylaod) => {
            const response = await guest_optin_rates(paylaod);
            return response;
        },
        shipments_create: async (paylaod) => {
            const response = await shipments_create(paylaod);
            return response;
        },
        guest_shipments_create: async (paylaod) => {
            const response = await guest_shipments_create(paylaod);
            return response;
        },
        getSettings: async (account_id) => {
            const response = await getSettings(account_id);
            return response;
        },
        quoteGetSettings: async (account_id) => {
            const response = await quoteGetSettings(account_id);
            return response;
        },
        getPudoLockersGuest: async () => {
            const response = await getPudoLockersGuest();
            return response;
        },
        what3words: async (words) => {
            const response = await what3words(words);
            return response;
        },
        invoiceable_dates: async () => {
            const response = await invoiceable_dates();
            return response;
        }
    }
};

export default NewOrderLogic;