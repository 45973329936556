import {
    API_URL,
    REACT_APP_WHAT3WORDS_URL,
    REACT_APP_WHAT3WORDS_KEY,
    GUEST_REQUEST_TOKEN,
} from "config.js";
import { post, get } from "./helpers";

export const getPudoLockers = async (id) => {
    try {
        const response = await get(`${API_URL}/lockers-data`);
        if (response?.status !== 200) {
            return false;
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

export const checkMigrate = async (payload) => {
    const response = await post(`${API_URL}/old-shipment/migrate`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const optin_rates = async (payload) => {
    const f_payload = {
        ...payload,
        requested_from: "portal",
    };
    const response = await post(`${API_URL}/rates/opt-in`, f_payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const rates = async (payload) => {
    const f_payload = {
        ...payload,
        requested_from: "portal",
    };
    const response = await post(`${API_URL}/rates`, f_payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const track = async (params) => {
    const response = await get(
        `${API_URL}/tracking/shipments/public?${params}`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const shipments_create = async (payload) => {
    const f_payload = {
        ...payload,
        requested_from: "portal",
    };
    const response = await post(`${API_URL}/shipments`, f_payload);
    const data = await response.json();
    return {
        status: response.status,
        data: data,
    };
};

export const getAvailableLocker = async (terminal) => {
    try {
        const url =
            "https://ksttcgavailability.azurewebsites.net/api/TerminalList?code=d9LkEkIgqdR3WPqzDOFB93Q73uA9n6DtlRFOKsheWYYuAFZgyFPgGg==&terminal=" +
            terminal;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const data = await response.json();
        return {
            data: data,
            status: response.status,
        };
    } catch (error) {
        return error;
    }
};

export const what3words = async (words) => {
    try {
        const encodedWords = encodeURIComponent(words);
        const url = `${REACT_APP_WHAT3WORDS_URL}${encodedWords}&key=${REACT_APP_WHAT3WORDS_KEY}`;
        const response = await fetch(url);
        const data = await response.json();
        return {
            data: data,
            status: response.status,
        };
    } catch (error) {
        return error;
    }
};

export const download_quote = async (payload) => {
    const response = await post(`${API_URL}/shipments/quote`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const generateInvoice = async (payload) => {
    const response = await post(
        `${API_URL}/old-shipment/generate-invoice`,
        payload
    );
    const data = await response.json();
    return {
        status: response.status,
        data: data,
    };
};

export const invoiceable_dates = async () => {
    const response = await get(`${API_URL}/old-shipment/invoiceable-dates`);
    if (response.status !== 200) return [];
    const data = await response.json();
    return data ?? [];
};

export const check_available_doors = async (locker_code) => {
    const response = await get(
        `${API_URL}/ma/check-available-doors/${locker_code}`
    );
    if (response.status !== 200) return [];
    const data = await response.json();
    return data ?? [];
};

export const get_lockers_kiosk_list = async () => {
    try {
        const response = await get(`${API_URL}/get-lockers-kiosk`);
        if (response?.status !== 200) {
            return false;
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

export const getPudoLockersGuest = async (id) => {
    try {
        const response = await get(`${API_URL}/guest/lockers-data`);
        if (response?.status !== 200) {
            return false;
        }
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

export const import_shipments = async (payload) => {
    const response = await post(`${API_URL}/import-shipments`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const guest_shipments_create = async (payload) => {
    const f_payload = {
        ...payload,
        requested_from: "portal",
    };
    const response = await fetch(`${API_URL}/guest/shipments`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            request_type: GUEST_REQUEST_TOKEN,
        },
        body: JSON.stringify(f_payload),
    });

    const data = await response.json();
    return {
        status: response.status,
        data: data,
    };
};

export const guest_optin_rates = async (payload) => {
    const f_payload = {
        ...payload,
        requested_from: "portal",
    };
    const response = await post(`${API_URL}/guest/rates-optin`, f_payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const resubmit_import_shipments = async (payload) => {
    const response = await post(
        `${API_URL}/import-shipments/resubmit`,
        payload
    );
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const send_pin_import_shipments = async (payload) => {
    const response = await post(
        `${API_URL}/import-shipments/send-pin`,
        payload
    );
    return {
        status: response.status,
        ...(await response.json()),
    };
};
export const guest_rates = async (payload) => {
    const f_payload = {
        ...payload,
        requested_from: "portal",
    };
    const response = await post(`${API_URL}/guest/rates`, f_payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const get_failed_shipments_import = async (shipment_import_id) => {
    const response = await get(
        `${API_URL}/get-failed-shipments-import/${shipment_import_id}`
    );
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const quoteGetShipment = async (id) => {
    const response = await fetch(`${API_URL}/guest/get-shipment/${id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            request_type: GUEST_REQUEST_TOKEN,
        },
    });
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const quoteGetShipmentResponse = async (id) => {
    const response = await fetch(`${API_URL}/quote/get-shipment-response/${id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            request_type: GUEST_REQUEST_TOKEN,
        },
    });
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};